<template>
  <div
    v-if="!authUIFlags.isFetching"
    id="app"
    class="app-wrapper"
    :class="{ 'app-rtl--wrapper': isRTLView }"
  >
    <template v-if="!accountUIFlags.isFetchingItem && currentAccountId">
      <trial-banner />
    </template>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
    <product-fruits />

    <connection-alert />

    <template v-if="hasCallChannelFeature">
      <browser-dialer />
      <call-widget has-image />
    </template>
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer';
import rtlMixin from 'shared/mixins/rtlMixin';
import accountMixin from './mixins/account';
import ConnectionAlert from 'shared/components/ConnectionAlert.vue';
import { LocalStorage } from 'shared/helpers/localStorage';
import TrialBanner from './components/app/TrialBanner.vue';
import ProductFruits from './components/app/ProductFruits.vue';
import BrowserDialer from './components/app/BrowserDialer.vue';
import CallWidget from './routes/dashboard/settings/inbox/channels/call/CallWidget.vue';

import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    WootSnackbarBox,
    TrialBanner,
    ProductFruits,
    ConnectionAlert,
    BrowserDialer,
    CallWidget,
  },

  mixins: [rtlMixin, accountMixin],

  data() {
    return {
      showAddAccountModal: false,
      latestChatwootVersion: null,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      accountUIFlags: 'accounts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
    hasCallChannelFeature() {
      return this.isFeatureEnabledonAccount(
        this.currentAccountId,
        'channel_call',
        false
      );
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.setLocale(window.hooryConfig.selectedLocale);
    // if (this.prefersDarkMode()) {
    //   document.documentElement.classList.add('dark-mode');
    // }
  },
  created() {
    this.$router.afterEach(to => {
      this.setDocumentTitle(to);
    });
  },
  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    setDocumentTitle(route) {
      document.title = route.meta.title
        ? `${this.$t(route.meta.title)} - Hoory AI`
        : 'Hoory AI';
    },
    prefersDarkMode() {
      const darkMode = LocalStorage.get('darkMode') || 'auto';
      const isOSOnDarkMode =
        darkMode === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      return isOSOnDarkMode || darkMode === 'dark';
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');
      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });
      const {
        locale,
        latest_chatwoot_version: latestChatwootVersion,
      } = this.getAccount(this.currentAccountId);
      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.setDocumentTitle(this.$route);
      this.updateRTLDirectionView(locale);
      this.latestChatwootVersion = latestChatwootVersion;
      vueActionCable.init(pubsubToken);

      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
.update-banner {
  height: var(--space-larger);
  align-items: center;
  font-size: var(--font-size-small) !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
