var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns profile--settings"},[_c('div',{staticClass:"small-12 columns"},[(_vm.subscription !== null)?_c('div',[_c('div',{staticClass:"border-bottom"},[_c('div',{staticClass:"page-title"},[_c('h5',[_vm._v("\n            "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.TITLE'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"badge"},[_vm._v("\n            "+_vm._s(_vm.planName)+" "+_vm._s(_vm.subscription.isTrial ? 'Trial' : '')+"\n          ")])]),_vm._v(" "),_c('p',{staticClass:"text-muted mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NOTE'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"plan-desc border-bottom"},[_c('h6',[_vm._v("\n          "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NAME'))+"\n          "),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.planName)+" Plan")])]),_vm._v(" "),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
            _vm.formatMessage(
              _vm.$t('BILLING_SETTINGS.CURRENT_PLAN.NEXT_CHARGE', {
                date: _vm.nextPaymentDate,
              })
            )
          ),expression:"\n            formatMessage(\n              $t('BILLING_SETTINGS.CURRENT_PLAN.NEXT_CHARGE', {\n                date: nextPaymentDate,\n              })\n            )\n          "}],staticClass:"text-muted"}),_vm._v(" "),(_vm.subscription.isTrial)?_c('div',{staticClass:"trial-box"},[_c('div',{staticClass:"emoji"},[_vm._v("👋")]),_vm._v(" "),_c('div',{},[_c('h6',[_vm._v("\n              "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.FREE_TRIAL_NOTE', {
                  planName: _vm.planName,
                }))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"note text-muted"},[_vm._v("\n              "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.TRIAL_END_NOTE', {
                  date: _vm.nextPaymentDate,
                  days: _vm.nextPaymentDays,
                }))+"\n            ")])])]):_vm._e(),_vm._v(" "),(_vm.hasPaymentMethod)?_c('h6',{staticClass:"sub-expiry"},[_vm._v("\n          "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.PAYMENT_METHOD', {
              card: _vm.getCardLastNumbers,
              brand: _vm.getCardBrand,
              expire: _vm.getCardExpireDate,
            }))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sub-details"},[_c('h6',[_vm._v("\n            "+_vm._s(_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.SUBSCRIPTION_TITLE'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v("\n                "+_vm._s(_vm.subscription.quantity.toString())+" Agent"+_vm._s(_vm.subscription.quantity > 1 ? '' : 's')+"\n                "),_c('span',{staticClass:"text-muted"},[_vm._v("x")]),_vm._v(" "+_vm._s(_vm.planName)+" Plan (at\n                "+_vm._s(_vm.paymentDetail)+"$/"+_vm._s(_vm.paymentPeriod)+")\n              ")]),_vm._v(" "),_c('div',{staticClass:"col right"},[_vm._v("$ "+_vm._s(_vm.paymentDetail.toFixed(2)))])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v("Total")]),_vm._v(" "),_c('div',{staticClass:"col right"},[_vm._v("$ "+_vm._s(_vm.paymentDetail.toFixed(2)))])])])])])]):_c('woot-loading-state',{attrs:{"message":_vm.$t('BILLING_SETTINGS.CURRENT_PLAN.LOADING')}}),_vm._v(" "),(_vm.currentUser.role === 'administrator')?_c('div',[_c('billing-item',{attrs:{"title":_vm.$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.TITLE'),"description":_vm.$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.DESCRIPTION'),"button-label":_vm.$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.BUTTON_TXT')},on:{"click":_vm.onClickBillingPortal}})],1):_vm._e(),_vm._v(" "),_c('billing-item',{attrs:{"title":_vm.$t('BILLING_SETTINGS.CHAT_WITH_US.TITLE'),"description":_vm.$t('BILLING_SETTINGS.CHAT_WITH_US.DESCRIPTION'),"button-label":_vm.$t('BILLING_SETTINGS.CHAT_WITH_US.BUTTON_TXT'),"button-icon":"chat-multiple"},on:{"click":_vm.onToggleChatWindow}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }