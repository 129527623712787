<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div v-if="isLoading" class="call-based-spinner spinner-cover">
      <spinner-hoory size="medium" color="#fff" />
    </div>
    <div class="column call-based-popup">
      <woot-modal-header
        :header-title="$t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.TITLE')"
      />
      <div class="main-content">
        <p class="primary-header margin-bottom-2">
          {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.HEADER') }}
        </p>
        <ul class="charge-details">
          <li class="fs-normal">
            {{
              $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.INFO_LIST.RECEIVE')
            }}
          </li>
          <li class="fs-normal">
            {{
              $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.INFO_LIST.INITATAE')
            }}
          </li>
          <li class="fs-normal">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.INFO_LIST.SMS') }}
          </li>
          <li class="fs-normal">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.INFO_LIST.AI') }}
          </li>
        </ul>
        <div class="main-message">
          <div class="main-message-icon">
            💴
          </div>
          <div class="main-message-content">
            <h6 class="fw-bold fs-default">
              {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.NOTE.TITLE') }}
            </h6>
            <p class="fs-normal">
              {{
                $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.NOTE.DESCRIPTION')
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="call-based-popup-actions row">
        <div class="medium-4 padding-right-1">
          <woot-button
            size="medium"
            variant="smooth"
            class="wide full margin-right-1"
            color-scheme="secondary"
            @click.prevent="execCreateChannel(false)"
          >
            {{
              $t(
                'INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.ACTIONS.CHOOSE_LATER'
              )
            }}
          </woot-button>
        </div>
        <div class="medium-8">
          <woot-button
            size="medium"
            variant="flat"
            class="full"
            color-scheme="primary"
            @click.prevent="execCreateChannel(true)"
          >
            {{
              $t(
                'INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.ACTIONS.CHOOSE_NUMBER'
              )
            }}
          </woot-button>
        </div>
      </div>
      <div class="business-number-tooltip">
        {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.CHARGED_INFO.ACTIONS.TOOLTIP') }}
      </div>
    </div>
  </woot-modal>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from 'dashboard/mixins/account';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    SpinnerHoory,
  },
  mixins: [inboxMixin, billingMixin, accountMixin, alertMixin],

  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      isLoading: false,
    };
  },
  methods: {
    async execCreateChannel(needNumber) {
      this.isLoading = true;
      try {
        const callChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          this.newInboxData
        );

        await this.buyChannel({
          inboxId: callChannel.id,
          accountId: this.accountId,
        });

        const stepParams = {
          page: 'new',
          inbox_id: callChannel.id,
        };

        const step = needNumber
          ? 'settings_inboxes_business_number'
          : 'settings_inboxes_add_agents';
        this.goToGivenStep(step, stepParams);
      } catch (error) {
        this.showAlert(`Error: ${error?.message}`);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.charge-details {
  margin: 0 0 var(--space-medium) var(--space-medium);
  font-size: var(--font-size-default);
  li {
    margin-bottom: var(--space-smaller);
  }
}
.business-number-tooltip {
  padding: 0 var(--space-medium) var(--space-medium);
  color: var(--s-600);
  font-size: var(--font-size-small);
}
</style>
