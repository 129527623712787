<template>
  <woot-dropdown-menu>
    <woot-dropdown-item class="incoming-call--toggle">
      <div class="info-wrap">
        <fluent-icon
          v-tooltip.right-start="
            $t('SIDEBAR.SET_RECEIVE_INCOMING_CALLS.INFO_TEXT')
          "
          icon="info"
          size="14"
          class="info-icon"
        />

        <span class="incoming-call--text">
          {{ $t('SIDEBAR.SET_RECEIVE_INCOMING_CALLS.TEXT') }}
        </span>
      </div>

      <woot-switch
        size="small"
        class="incoming-call--switch"
        :value="canReceiveIncomingCalls"
        @input="updateReceiveIncomingCallsStatus"
      />
    </woot-dropdown-item>
    <woot-dropdown-divider />
  </woot-dropdown-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider';
import { LocalStorage } from 'shared/helpers/localStorage';

export default {
  components: {
    WootDropdownDivider,
    WootDropdownMenu,
    WootDropdownItem,
  },

  mixins: [clickaway, alertMixin],

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
      darkMode: 'auto',
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      canReceiveIncomingCalls: 'getCurrentUserReceiveIncomingCallsStatus',
    }),
  },
  mounted() {
    /* eslint vue/return-in-computed-property : "off" */
    /* eslint vue/no-side-effects-in-computed-properties : "off" */
    this.darkMode = LocalStorage.get('darkMode') || 'auto';
  },
  methods: {
    updateReceiveIncomingCallsStatus(canReceiveIncomingCalls) {
      this.$store.dispatch('updateReceiveIncomingCallsStatus', {
        accountId: this.currentAccountId,
        canReceiveIncomingCalls,
      });
    },
    isDarkColorDecided(chosenColor) {
      const isOSOnDarkMode =
        chosenColor === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      return isOSOnDarkMode || this.darkMode === 'dark';
    },
    changeColorMode(e) {
      const { value } = e.target;
      LocalStorage.set('darkMode', value);
      LocalStorage.set(
        'chakra-ui-color-mode',
        value === 'auto' ? 'system' : value
      );
      this.darkMode = value;

      if (this.isDarkColorDecided(value)) {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.remove('dark-mode');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables';

.incoming-call--toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--space-smaller);
  margin: 0;

  .info-wrap {
    display: flex;
    align-items: center;
  }

  .info-icon {
    margin-top: -1px;
  }

  .incoming-call--switch {
    margin: -1px var(--space-micro) 0;
  }

  .incoming-call--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    color: var(--s-700);
  }

  .dark-mode--text {
    margin: 0 var(--space-smaller);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    color: var(--s-700);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;

    .beta {
      background: #05921a;
      color: #fff;
      font-size: 9px;
      padding: 1px 4px;
      border-radius: 7px;
      position: absolute;
      top: 12%;
      left: 7.5rem;
    }

    select {
      height: 3.2rem;
      width: 9rem;
      line-height: 114%;
      padding: 0 26px 0 8px;
    }
  }
}
</style>
