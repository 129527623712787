import { render, staticRenderFns } from "./HelpCenterLayout.vue?vue&type=template&id=4c7d4fe5&"
import script from "./HelpCenterLayout.vue?vue&type=script&lang=js&"
export * from "./HelpCenterLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports