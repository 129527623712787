<template>
  <div class="row content-box full-height">
    <woot-wizard
      class="hide-for-small-only medium-3 columns"
      :global-config="globalConfig"
      :items="items"
    />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      newInboxType: 'inboxes/getNewInboxType',
    }),
    getNewInboxType() {
      if (
        [
          'settings_inboxes_business_number',
          'settings_inboxes_attributes',
          'settings_call_inbox_finish',
        ].includes(this.$route.name)
      ) {
        return 'call';
      }
      return this.newInboxType;
    },
    items() {
      const flowItems =
        this.getNewInboxType === 'call'
          ? this.$t('INBOX_MGMT.CREATE_CALL_FLOW')
          : this.$t('INBOX_MGMT.CREATE_FLOW');
      const flowItemsData = flowItems.map(item => ({
        ...item,
        body: this.useInstallationName(
          item.body,
          this.globalConfig.installationName
        ),
      }));
      return flowItemsData;
    },
  },
};
</script>
