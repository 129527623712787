<template>
  <div class="date-picker">
    <date-picker
      type="datetime"
      :range="true"
      :confirm="true"
      :show-second="false"
      format="DD-MM-YY HH:mm"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :time-picker-options="{
        start: '00:00',
        step: '00:10',
        end: '23:59',
        format: 'HH:mm',
      }"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style module>
.mx-calendar-content {
  height: auto;
}
</style>
