<template>
  <div class="manage-subscription">
    <div>
      <h6>{{ title }}</h6>
      <p class="manage-subscription--description">
        {{ description }}
      </p>
    </div>
    <div>
      <woot-button
        class="btn"
        variant="smooth"
        :icon="buttonIcon"
        @click="$emit('click')"
      >
        {{ buttonLabel }}
      </woot-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },

    buttonIcon: {
      type: String,
      default: 'edit',
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  background: var(--v-100);
  color: var(--v-400);
  border-color: var(--s-25);

  &:hover {
    background: var(--b-100);
    color: var(--b-700);
  }
}
</style>
