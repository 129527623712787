<template>
  <div v-if="inbox.numbers.length">
    <settings-section
      :title="$t('INBOX_MGMT.PHONE_NUMBERS.NUMBERS')"
      :sub-title="$t('INBOX_MGMT.PHONE_NUMBERS.TITLE')"
    >
      <div>
        <div class="phone-number-content">
          <div class="phone-number-flag-content">
            <VFlag
              :flag="primaryNumber.iso_country"
              class="phone-number-flag margin-right-2"
            />
            <div class="phone-number-text">
              {{ primaryNumber.friendly_name }}
            </div>
          </div>
        </div>
        <woot-button
          type="button"
          variant="smooth"
          color-scheme="alert"
          @click="toggleDeleteModal"
        >
          {{ $t('INBOX_MGMT.PHONE_NUMBERS.DELETE_NUMBER') }}
        </woot-button>
      </div>
    </settings-section>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :is-loading="isFetchingUIFlag"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="primaryNumber.friendly_name"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
  </div>
  <div v-else class="get-number-section">
    <div class="get-number-img">
      <img :src="callGif" />
    </div>
    <p class="fw-bold fs-two margin-top-3 margin-bottom-2">
      {{ $t('INBOX_MGMT.PHONE_NUMBERS.GET_NUMBER') }}
    </p>
    <p class="fs-normal margin-bottom-3">
      {{ $t('INBOX_MGMT.PHONE_NUMBERS.GET_NUMBER_DESC') }}
    </p>
    <woot-button
      type="button"
      color-scheme="primary"
      size="medium"
      class="margin-bottom-3"
      @click="addBusinessNumber()"
    >
      {{ $t('INBOX_MGMT.PHONE_NUMBERS.PURCHASE_NUMBER') }}
    </woot-button>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection';
import callGif from 'dashboard/assets/images/call.gif';
import VFlag from 'dashboard/components/ui/VFlag';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from 'dashboard/mixins/account';

export default {
  components: {
    SettingsSection,
    VFlag,
  },
  mixins: [alertMixin, billingMixin, inboxMixin, accountMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      callGif,
      showDeleteModal: false,
      isLoading: true,
    };
  },
  methods: {
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async confirmDeletion() {
      this.isLoading = true;
      const data = {
        inboxId: this.inbox.id,
        accountId: this.accountId,
        numberId: this.primaryNumber.id,
      };
      await this.deletePhoneNumber(data);
      this.closeDelete();
      this.isLoading = false;
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
    addBusinessNumber() {
      const stepParams = {
        page: 'new',
        inbox_id: this.inbox.id,
      };
      this.setNewInboxType('call');
      this.goToGivenStep('settings_inboxes_business_number', stepParams);
    },
  },
};
</script>

<style scoped lang="scss">
.get-number-section {
  height: calc(100vh - 219px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  text-align: center;
  .get-number-img {
    height: 140px;
    width: 140px;
  }
}
.phone-number-content {
  cursor: pointer;
  display: flex;
  border: 1px solid var(--s-100);
  background-color: var(--s-50);
  border-radius: var(--border-radius-medium);
  justify-content: space-between;
  align-items: center;
  padding: var(--space-one);
  margin-bottom: var(--space-snug);
  .phone-number-flag-content {
    display: flex;
    justify-content: start;
    align-items: center;
    .phone-number-flag {
      height: 24px;
      width: 24px;
      overflow: hidden;
      border-radius: var(--border-radius-rounded);
    }
    .phone-number-text {
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-medium);
      color: var(--s-500);
      letter-spacing: 0.3px;
    }
  }
}
.call-based-spinner {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
  padding-top: var(--space-large);
}
</style>
