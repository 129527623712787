/* global axios */
import wootConstants from 'dashboard/constants/globals.js';
import auth from './auth';
import ApiClient from './ApiClient';

class CallAPI extends ApiClient {
  SERVICES_PATH = wootConstants.SERVICES_PATH;

  requestToken() {
    const authToken = auth.getAuthToken();
    return axios.get(`/${this.SERVICES_PATH.CALL}/v1/token`, {
      headers: { authorization: authToken },
    });
  }
}

export default new CallAPI();
