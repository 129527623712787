<template>
  <div class="contact--profile">
    <div class="contact--info">
      <div class="contact-info--header">
        <thumbnail
          v-if="showAvatar"
          :src="contact.thumbnail"
          size="56px"
          :username="contact.name"
          :status="contact.availability_status"
        />
        <woot-button
          v-if="showCloseButton"
          :icon="closeIconName"
          class="clear secondary close-button--rtl"
          @click="onPanelToggle"
        />
      </div>

      <div class="contact--details">
        <div v-if="showAvatar" class="contact--name-wrap">
          <h3 class="sub-block-title contact--name">
            {{ contact.name }}
          </h3>
          <fluent-icon
            v-if="contact.created_at"
            v-tooltip="
              `${$t('CONTACT_PANEL.CREATED_AT_LABEL')} ${dynamicTime(
                contact.created_at
              )}`
            "
            icon="information"
            size="16"
            view-box="0 0 20 20"
          />
          <a
            :href="contactProfileLink"
            class="fs-default"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <woot-button
              size="medium"
              icon="open"
              variant="clear"
              color-scheme="secondary"
            />
          </a>
        </div>

        <p v-if="additionalAttributes.description" class="contact--bio">
          {{ additionalAttributes.description }}
        </p>
        <social-icons :social-profiles="socialProfiles" />
        <div class="contact--metadata">
          <contact-info-row
            :href="contact.email ? `mailto:${contact.email}` : ''"
            :value="contact.email"
            icon="mail"
            emoji="✉️"
            :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            show-copy
          />
          <contact-info-row
            :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
            :value="contact.phone_number"
            icon="call"
            emoji="📞"
            :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
            show-copy
          />
          <contact-info-row
            v-if="contact.identifier"
            :value="contact.identifier"
            icon="contact-identify"
            emoji="🪪"
            :title="$t('CONTACT_PANEL.IDENTIFIER')"
          />
          <contact-info-row
            :value="additionalAttributes.company_name"
            icon="building-bank"
            emoji="🏢"
            :title="$t('CONTACT_PANEL.COMPANY')"
          />
          <contact-info-row
            v-if="location || additionalAttributes.location"
            :value="location || additionalAttributes.location"
            icon="map"
            emoji="🌍"
            :title="$t('CONTACT_PANEL.LOCATION')"
          />
        </div>
      </div>
      <div class="contact-actions">
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')"
          title="$t('CONTACT_PANEL.NEW_MESSAGE')"
          class="new-message"
          icon="chat-send"
          size="small"
          @click="toggleConversationModal"
        />
        <woot-button
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          class="edit-contact"
          icon="edit"
          variant="smooth"
          size="small"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          fill-color="none"
          @click="toggleEditModal"
        />
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')"
          title="$t('CONTACT_PANEL.MERGE_CONTACT')"
          class="merge-contact"
          icon="merge"
          variant="smooth"
          size="small"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          fill-color="none"
          color-scheme="secondary"
          :disabled="uiFlags.isMerging"
          @click="openMergeModal"
        />
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
          title="$t('DELETE_CONTACT.BUTTON_LABEL')"
          class="delete-contact"
          icon="delete"
          variant="smooth"
          size="small"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          fill-color="none"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />
        <woot-button
          v-if="isAdmin && !!createdAtIp"
          v-tooltip="$t('BAN_CONTACT.BUTTON_LABEL')"
          title="$t('BAN_CONTACT.BUTTON_LABEL')"
          class="ban-contact"
          variant="smooth"
          size="small"
          color-scheme="alert"
          :disabled="uiFlags.isBanning"
          @click="toggleBanModal"
        >
          {{
            contactIsBanned
              ? $t('BAN_CONTACT.UNBAN_BUTTON_LABEL')
              : $t('BAN_CONTACT.BAN_BUTTON_LABEL')
          }}
        </woot-button>
      </div>
      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      />
      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact="contact"
        @cancel="toggleConversationModal"
      />
      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      />
    </div>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
    <contact-ban-modal
      v-if="showBanModal"
      :show.sync="showBanModal"
      :on-close="closeDelete"
      :on-confirm="confirmBan"
      :show-form="!contactIsBanned"
      :title="
        contactIsBanned
          ? $t('BAN_CONTACT.CONFIRM_UNBAN.TITLE')
          : $t('BAN_CONTACT.CONFIRM_BAN.TITLE')
      "
      :message="
        contactIsBanned
          ? $t('BAN_CONTACT.CONFIRM_UNBAN.MESSAGE')
          : $t('BAN_CONTACT.CONFIRM_BAN.MESSAGE')
      "
      :message-value="confirmDeleteMessage"
      :confirm-text="
        contactIsBanned
          ? $t('BAN_CONTACT.CONFIRM_UNBAN.YES')
          : $t('BAN_CONTACT.CONFIRM_BAN.YES')
      "
      :reject-text="
        contactIsBanned
          ? $t('BAN_CONTACT.CONFIRM_UNBAN.NO')
          : $t('BAN_CONTACT.CONFIRM_BAN.NO')
      "
      :initial-ip-address="createdAtIp || ''"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import EditContact from './EditContact';
import NewConversation from './NewConversation';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import ContactBanModal from 'dashboard/modules/contact/ContactBanModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';
import wootConstants from 'dashboard/constants/globals.js';

const { SERVICES_PATH } = wootConstants;

export default {
  components: {
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
    ContactBanModal,
  },
  mixins: [alertMixin, adminMixin, clickaway, timeMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeIconName: {
      type: String,
      default: 'chevron-right',
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      showBanModal: false,
      contactIsBanned: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'contacts/getUIFlags' }),
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete/Ban Modal
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },
    createdAtIp() {
      return this.contact.additional_attributes?.created_at_ip;
    },
  },
  watch: {
    contact(prev, curr) {
      if (!curr?.id || prev?.id === curr?.id) return;
      this.loadBlockStatus();
    },
  },
  mounted() {
    this.loadBlockStatus();
  },
  methods: {
    loadBlockStatus() {
      const accountId = this.$route.params.accountId;
      const contactId = this.contact.id;
      const ipAddress = this.contact.additional_attributes?.created_at_ip;
      if (ipAddress) {
        axios
          .get(
            `/${SERVICES_PATH.WEB_SIDECAR}/v1/accounts/${accountId}/contacts/${contactId}/ip/${ipAddress}/ban_status`
          )
          .then(response => {
            if (!this.contact || contactId === this.contact.id)
              this.contactIsBanned = response.data.banned;
          });
      }
    },
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleBanModal() {
      this.showBanModal = !this.showBanModal;
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    confirmBan(ipAddress, durationHours) {
      this.banContact(ipAddress, durationHours);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showBanModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    async banContact(ipAddress, durationHours) {
      try {
        const params = new URLSearchParams({
          duration_hours: durationHours,
        });
        const accountId = this.$route.params.accountId;
        const contactId = this.contact.id;
        const response = await axios.post(
          `/${
            SERVICES_PATH.WEB_SIDECAR
          }/v1/accounts/${accountId}/contacts/${contactId}/ip/${ipAddress}/toggle_ban?${params.toString()}`
        );
        this.contactIsBanned = response.data.banned;
      } catch (error) {
        let errorMessage = this.$t('BAN_CONTACT.API.ERROR_MESSAGE');
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          errorMessage = error.response.data.detail;
        }

        this.showAlert(errorMessage);
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: left;
}

.contact-info--header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 var(--space-smaller);

  a {
    color: var(--color-body);
  }
}

.contact--metadata {
  margin-bottom: var(--space-slab);
}

.contact-actions {
  margin-top: var(--space-small);
}

.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .ban-contact,
  .delete-contact {
    margin-right: var(--space-small);
  }
}

.merge-summary--card {
  padding: var(--space-normal);
}

.contact--bio {
  word-wrap: break-word;
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}
</style>
