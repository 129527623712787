var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"wizard-body small-9 columns"},[_c('form',{staticClass:"call-based-body full-height",on:{"submit":function($event){$event.preventDefault();return _vm.execSetNewInboxData($event)}}},[_c('div',[_c('page-header',{staticClass:"channel-type-header",attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.TITLE'),"header-content":_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.DESCRIPTION')}}),_vm._v(" "),_c('section',[_c('div',{staticClass:"channel-price"},[_c('div',{staticClass:"channel-price-section"},[_c('p',[_c('span',{staticClass:"web-base-icon"},[_vm._v("\n                ☎️\n              ")]),_vm._v(" "),_c('span',{staticClass:"fs-default fw-normal"},[_vm._v("\n                "+_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.TITLE'))+"\n              ")])]),_vm._v(" "),(_vm.isBillingDataLoaded)?_c('span',{staticClass:"fs-default fw-normal"},[_vm._v("\n              "+_vm._s(_vm.isMonthly
                  ? _vm.$t('PRICING_PLANS.PLAN.MONTHLY')
                  : _vm.$t('PRICING_PLANS.PLAN.YEARLY'))+"\n              "),_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Your main subscription type'),expression:"'Your main subscription type'"}],attrs:{"icon":"information","size":"16","view-box":"0 0 20 20"}})],1):_vm._e()]),_vm._v(" "),_c('h6',{staticClass:"margin-top-1 margin-bottom-1 channel-price-section"},[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.SUBSCRIPTIONS'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"channel-price-section margin-bottom-1"},[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.CHANNEL'))+"\n            ")]),_vm._v(" "),(_vm.isBillingDataLoaded)?_c('div',[_vm._v("\n              "+_vm._s(_vm.formattedPrice(_vm.callBaseChannelPrice))+" /\n              "+_vm._s(_vm.isMonthlyText)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"channel-price-section"},[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.NUMBER'))+"\n            ")]),_vm._v(" "),(_vm.isBillingDataLoaded)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.STARTING_FROM'))+"\n              "+_vm._s(_vm.formattedPrice(_vm.minCurrentPrice * 100))+" /\n              "+_vm._s(_vm.isMonthlyText)+"\n            ")]):_vm._e()])])]),_vm._v(" "),_c('section',{staticClass:"channel-details"},[_c('div',{staticClass:"medium-12 columns"},[_c('label',{class:{ error: _vm.$v.channelName.$error }},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                ("" + (_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.TOOLTIP')))
              ),expression:"\n                `${$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.TOOLTIP')}`\n              "}],attrs:{"icon":"info","size":"16"}}),_vm._v(" "),_c('span',{staticClass:"fs-snug"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.LABEL')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.channelName),expression:"channelName",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.FORM.NAME.PLACEHOLDER')},domProps:{"value":(_vm.channelName)},on:{"blur":[_vm.$v.channelName.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.channelName=$event.target.value.trim()}}}),_vm._v(" "),(_vm.$v.channelName.$error)?_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.CHANNEL_NAME.ERROR')))]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('label',{class:{
              error: _vm.$v.channelUrl.$error,
              'channel-url-input': true,
            }},[_c('inbox-slug-input',{ref:"InboxSlugInput",attrs:{"value":_vm.channelUrl,"channel-id":"0","show-copy-btn":false},on:{"input":function($event){_vm.channelUrl = $event;
                _vm.isChannelWebsiteChanged = true;},"ok":function($event){_vm.slugError = false},"error":function($event){_vm.slugError = true},"blur":_vm.$v.channelUrl.$touch}}),_vm._v(" "),(_vm.$v.channelUrl.$error)?_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.CALL_CHANNEL.CHANNEL_NAME.ERROR')))]):_vm._e()],1)])])],1),_vm._v(" "),_c('div',[_c('woot-submit-button',{staticClass:"next-btn",attrs:{"button-text":"Continue"}})],1)]),_vm._v(" "),_c('section',{staticClass:"call-based-body"},[_c('woot-modal',{attrs:{"show":_vm.showCallBasedPopup,"on-close":_vm.hideCallBasedPopup},on:{"update:show":function($event){_vm.showCallBasedPopup=$event}}},[_c('BusinessNumberConfirm',{attrs:{"on-close":_vm.hideCallBasedPopup}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }