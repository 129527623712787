<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column call-based-popup">
      <section>
        <div class="main-content text-center">
          <div class="margin-top-3">
            <svg
              width="80"
              height="80"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.4674 20C37.7341 17.4167 36.6508 13.3667 33.4008 12.2667C34.0674 8.89999 31.1008 5.94999 27.7508 6.61666C26.6508 3.36666 22.6008 2.28332 20.0174 4.54999C17.4341 2.28332 13.3841 3.36666 12.2841 6.61666C8.91745 5.94999 5.96745 8.91666 6.63411 12.2667C3.38411 13.3833 2.30078 17.4333 4.56745 20.0167C2.30078 22.6 3.38411 26.65 6.63411 27.75C5.96745 31.1167 8.93411 34.0667 12.2841 33.4C13.3841 36.65 17.4341 37.7333 20.0174 35.4667C22.6008 37.7333 26.6508 36.65 27.7508 33.4C31.1174 34.0667 34.0674 31.1 33.4008 27.75C36.6508 26.6333 37.7341 22.5833 35.4674 20Z"
                fill="#19B46C"
              />
              <path
                d="M17.9676 25.4833C17.6509 25.4833 17.3342 25.3667 17.0842 25.1167L11.8176 19.85C11.3342 19.3667 11.3342 18.5667 11.8176 18.0833C12.3009 17.6 13.1009 17.6 13.5842 18.0833L17.9676 22.4667L26.8842 13.55C27.3676 13.0667 28.1676 13.0667 28.6509 13.55C29.1342 14.0333 29.1342 14.8333 28.6509 15.3167L18.8509 25.1167C18.6009 25.3667 18.2842 25.4833 17.9676 25.4833Z"
                fill="#EDEBEA"
              />
            </svg>
          </div>
          <h6 class="margin-top-3 fs-medium fw-bold">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.COMPLETED') }}
          </h6>
          <p class="fs-small">
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.SUCCESS_MSG') }}
          </p>
          <div class="main-message margin-top-2">
            <div class="main-message-content">
              <p class="margin-bottom-1 fs-normal">
                {{
                  $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.PURCHASED_NUMBER')
                }}
              </p>
              <h6 class="fw-bold fs-medium margin-bottom-0">
                {{ selectedNumber.phone_number }}
              </h6>
            </div>
          </div>
        </div>
        <div class="call-based-popup-actions">
          <woot-button
            size="medium"
            variant="flat"
            class="full"
            color-scheme="primary"
            @click.prevent="onClose"
          >
            {{ $t('INBOX_MGMT.ADD.CALL_CHANNEL.PURCHASE.ADD_AGENTS') }}
          </woot-button>
        </div>
      </section>
    </div>
  </woot-modal>
</template>

<script>
import inboxMixin from 'shared/mixins/inboxMixin';

export default {
  mixins: [inboxMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
