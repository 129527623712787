import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      audioRecords: 'stickyBanner/getAudioRecords',
      activeRecord: 'stickyBanner/getActiveRecord',
      playPause: 'stickyBanner/getPlayPause',
      nextPrev: 'stickyBanner/getNextPrev',
      seekData: 'stickyBanner/getSeekData',
      rateData: 'stickyBanner/getRateData',
      bannerVisibility: 'stickyBanner/getBannerVisibility',
      accountId: 'getCurrentAccountId',
    }),
    hasNextRecord() {
      return this.getNextOrPrevious(
        this.audioRecords,
        this.activeRecord.id,
        'next'
      );
    },
    hasPreviousRecord() {
      return this.getNextOrPrevious(
        this.audioRecords,
        this.activeRecord.id,
        'previous'
      );
    },
    isPlayingRecordConversationActive() {
      const conversationId =
        this.$route.params.conversation_id || this.$route.params.conversationId;
      return Number(conversationId) === this.activeRecord.conversation_id;
    },
  },
  methods: {
    setAudioRecords(records) {
      this.$store.commit('stickyBanner/setAudioRecords', records);
    },
    setActiveRecord(record) {
      this.$store.commit('stickyBanner/setActiveRecord', record);
    },
    setPlayPause(data) {
      this.$store.commit('stickyBanner/setPlayPause', data);
    },
    setSeekData(data) {
      this.$store.commit('stickyBanner/setSeekData', data);
    },
    setRateData(data) {
      this.$store.commit('stickyBanner/setRateData', data);
    },
    setNextPrev(data) {
      this.$store.commit('stickyBanner/setNextPrev', data);
    },
    setBannerVisibility(visible) {
      this.$store.commit('stickyBanner/setBannerVisibility', visible);
    },
    goToActiveConversation() {
      if (
        !this.$route.params.conversation_id ||
        !this.isPlayingRecordConversationActive
      ) {
        this.$router.push(
          `/app/accounts/${this.accountId}/inbox/${this.activeRecord.inbox_id}/conversations/${this.activeRecord.conversation_id}`
        );
      }
    },

    generateRandomId(length = 8) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    formatTime(seconds) {
      if (seconds === Infinity) {
        return '0:00';
      }
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    seekHandler(recordData, position) {
      if (recordData.id === this.activeRecord.id) {
        this.setSeekData(position);
      }
    },
    rateHandler(recordData, data) {
      if (recordData.id === this.activeRecord.id) {
        this.setRateData(data);
      }
    },
    previousHandler(data) {
      const hasPrevious = this.getNextOrPrevious(
        this.audioRecords,
        data.id,
        'previous'
      );
      if (hasPrevious) {
        this.setActiveRecord(hasPrevious);
        this.setNextPrev('previous');
      }
    },
    nextHandler(data) {
      const hasNext = this.getNextOrPrevious(
        this.audioRecords,
        data.id,
        'next'
      );
      if (hasNext) {
        this.setActiveRecord(hasNext);
        this.setNextPrev('next');
      } else {
        this.setPlayPause('stop');
      }
    },
    getNextOrPrevious(data, id, direction) {
      const currentIndex = data.findIndex(file => file.id === id);
      if (currentIndex === -1) {
        return null;
      }
      if (direction === 'next') {
        return data[currentIndex + 1] || null;
      }
      return data[currentIndex - 1] || null;
    },
  },
};
