<template>
  <div>
    <div
      v-if="!isCallWidgetShown"
      :class="{
        'call-widget-btn': true,
      }"
      :style="`bottom: ${bottom}px; right: ${right}px`"
    >
      <div class="call-widget-icon-wrapper" @click.prevent="toggleChat">
        <fluent-icon
          icon="call-widget-open"
          size="36"
          view-box="0 0 36 36"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.2"
          fill-color="none"
          class="cursor-pointer"
        />
      </div>

      <div class="call-widget-duration">0:32</div>
      <div class="call-widget-img">
        <img v-if="hasImage" :src="image" />
        <div
          v-else
          class="call-widget-caller-name"
          :style="{ backgroundColor: generatedColor }"
        >
          JS
        </div>
      </div>
      <div
        class="call-widget-icon-wrapper"
        :style="`cursor: ${cursor};`"
        @mousedown="mouseDown"
      >
        <fluent-icon size="24" view-box="0 0 24 24" icon="call-widget-grab" />
      </div>
      <div
        class="call-widget-background-image"
        :style="{
          backgroundImage: `linear-gradient(${defaultColor}, ${defaultColor}), ${
            hasImage
              ? `url(${image})`
              : `linear-gradient(${generatedColor}, ${generatedColor})`
          }`,
        }"
      />
    </div>
    <div
      v-if="!isCallWidgetHidden"
      class="call-widget-box"
      :class="{
        'call-show-box': isCallWidgetShown,
        'call-hide-box': isCallWidgetHiding,
      }"
      :style="
        isCallWidgetHiding
          ? { bottom: `${bottom + 32}px`, right: `${right}px` }
          : {}
      "
    >
      <div class="call-widget-content">
        <div class="call-widget-content-icon" @click.prevent="toggleChat">
          <fluent-icon
            icon="call-widget-close"
            size="36"
            view-box="0 0 36 36"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.2"
            fill-color="none"
          />
        </div>
        <div class="call-widget-content-avatar">
          <img v-if="hasImage" class="call-widget-img" :src="image" />
          <div
            v-else
            class="call-widget-caller-name"
            :style="{ backgroundColor: generatedColor }"
          >
            JS
          </div>
        </div>
        <div class="call-widget-content-data">
          <p class="call-widget-content-number margin-bottom-0">
            +374 41 288222
          </p>
          <p class="call-widget-content-author">Hannah Montana</p>
          <p v-if="!answered" class="call-widget-content-status">
            {{ $t('INBOX_MGMT.CALL_WIDGET.IS_CALLING') }}...
          </p>
          <p v-else class="call-widget-content-duration">0:21</p>
        </div>
        <div class="call-widget-content-actions margin-bottom-1">
          <div class="call-widget-content-action mute" @click="muted = !muted">
            <fluent-icon
              v-if="muted"
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-mute"
            />
            <fluent-icon
              v-else
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-unmute"
            />
          </div>
          <div
            class="call-widget-content-action decline"
            @click="answered = false"
          >
            <fluent-icon
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-decline"
            />
            <div v-if="answered" class="padding-left-1 fs-normal">
              {{ $t('INBOX_MGMT.CALL_WIDGET.DISMISS') }}
            </div>
          </div>
          <div
            v-if="!answered"
            class="call-widget-content-action answer"
            @click="answered = true"
          >
            <fluent-icon
              size="24"
              view-box="0 0 24 24"
              icon="call-widget-answer"
            />
          </div>
        </div>
        <button class="call-widget-content-conversation">
          <span>{{ $t('INBOX_MGMT.CALL_WIDGET.GO_TO_CONVERSATION') }}</span>
          <fluent-icon
            size="16"
            view-box="0 0 16 16"
            icon="call-widget-right-arrow"
          />
        </button>
        <div class="call-widget-content-powered">
          <div class="fs-mini">
            {{ $t('INBOX_MGMT.CALL_WIDGET.POWERED_BY') }}
          </div>
          <span class="padding-left-1 fw-bold fs-default">hoory.</span>
        </div>
      </div>
      <div
        class="call-widget-background-image"
        :style="{
          backgroundImage: `linear-gradient(${defaultColor}, ${defaultColor}), ${
            hasImage
              ? `url(${image})`
              : `linear-gradient(${generatedColor}, ${generatedColor})`
          }`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasImage: { type: Boolean, default: false },
  },
  data() {
    return {
      image:
        'https://i.pinimg.com/originals/68/c9/be/68c9be968cbc771d425095356b75669b.jpg',
      isCallWidgetHidden: true,
      isCallWidgetShown: false,
      isCallWidgetHiding: false,
      bottom: 25,
      right: 25,
      isDragging: false,
      answered: false,
      muted: false,
      cursor: 'grab',
      defaultColor: 'rgba(14, 11, 23, 0.8)',
      generatedColor: null,
    };
  },
  computed: {
    generateDarkColor() {
      const r = Math.floor(Math.random() * 156);
      const g = Math.floor(Math.random() * 156);
      const b = Math.floor(Math.random() * 156);
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
  created() {
    this.generatedColor = this.generateDarkColor;
  },
  methods: {
    toggleChat() {
      if (this.isCallWidgetHidden) {
        this.isCallWidgetHidden = false;
        this.isCallWidgetShown = true;
      } else if (!this.isCallWidgetHiding) {
        this.isCallWidgetHiding = true;
        this.isCallWidgetShown = false;
      } else {
        this.isCallWidgetShown = true;
        this.isCallWidgetHiding = false;
      }
    },
    mouseDown() {
      this.isDragging = true;
      this.cursor = 'grabbing';
      document.addEventListener('mousemove', this.mouseMove);
      document.addEventListener('mouseup', this.mouseUp);
      document.addEventListener('selectstart', this.preventTextSelection);
    },
    mouseMove(event) {
      if (this.isDragging) {
        this.right = window.innerWidth - event.clientX - 46;
        this.bottom = window.innerHeight - event.clientY - 15;
      }
    },
    mouseUp() {
      this.isDragging = false;
      this.cursor = 'grab';
      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('mouseup', this.mouseUp);
      document.removeEventListener('selectstart', this.preventTextSelection);
    },
    preventTextSelection(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
$call-widget-height: 365px;
$call-widget-width: 361px;
$call-widget-color-default: #0e0b17;
$call-widget-color-green: #3bcb88;

@-webkit-keyframes openbox {
  0% {
    height: var(--space-zero);
    width: var(--space-zero);
  }
  100% {
    height: $call-widget-height;
    width: $call-widget-width;
  }
}

@keyframes openbox {
  0% {
    height: var(--space-zero);
    width: var(--space-zero);
  }
  100% {
    height: $call-widget-height;
    width: $call-widget-width;
  }
}

@-webkit-keyframes closebox {
  0% {
    height: $call-widget-height;
    width: $call-widget-width;
  }
  100% {
    height: var(--space-zero);
    width: var(--space-zero);
  }
}

@keyframes closebox {
  0% {
    height: $call-widget-height;
    width: $call-widget-width;
  }
  100% {
    height: var(--space-zero);
    width: var(--space-zero);
  }
}

.call-widget-btn {
  background: rgba($call-widget-color-default, 0.93);
  color: var(--white);
  height: 173px;
  width: 92px;
  border-radius: 45px;
  display: flex;
  z-index: var(--z-index-highest);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-small) var(--space-zero);
  position: fixed;
  z-index: 1000;
  overflow: hidden !important;
  transition: transform 0.3s ease;
  .call-widget-img {
    z-index: 100;
    height: 68px;
    width: 68px;
    border-radius: var(--border-radius-rounded);
    margin: var(--space-smaller);
    border: 3px solid $call-widget-color-default;
    outline: 2px solid $call-widget-color-green;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .call-widget-duration {
    color: $call-widget-color-green;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-normal);
    z-index: 100;
  }
  .call-widget-icon-wrapper {
    z-index: 100;
    border-radius: var(--border-radius-normal);
    display: flex;
    align-items: center;
  }
  .call-widget-icon-wrapper:hover {
    opacity: 0.8;
  }

  .call-widget-background-image {
    transform: scale(2);
    filter: blur(3px);
  }
}

.call-widget-btn:hover {
  transform: scale(1.1);
}
.call-widget-box {
  position: relative;
  z-index: 100;
  overflow: hidden !important;
  border-radius: var(--border-radius-full);
  bottom: var(--space-medium);
  right: var(--space-medium);

  &.call-hide-box {
    -webkit-animation: closebox 0.4s ease-out;
    animation: closebox 0.4s ease-out;
    height: var(--space-zero);
    width: 0;
    position: absolute;
    opacity: 0.3;
  }

  &.call-show-box {
    -webkit-animation: openbox 0.25s ease-in;
    animation: openbox 0.25s ease-in;
    height: $call-widget-height;
    width: $call-widget-width;
    position: absolute;
  }

  .call-widget-content {
    position: relative;
    z-index: var(--z-index-low);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-medium) var(--space-medium) var(--space-normal);
    height: 100%;
    .call-widget-content-icon {
      position: absolute;
      right: var(--space-slab);
      top: var(--space-one);
      color: var(--white);
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .call-widget-content-avatar {
      height: 68px;
      width: 68px;
      border-radius: var(--border-radius-rounded);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .call-widget-content-number {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-two);
      color: var(--white);
    }
    .call-widget-content-author {
      color: var(--white);
      font-size: var(--font-size-normal);
    }
    .call-widget-content-status {
      color: var(--white);
      font-size: var(--font-size-default);
    }
    .call-widget-content-duration {
      font-size: var(--font-size-default);
      color: $call-widget-color-green;
    }
    .call-widget-content-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .call-widget-content-actions {
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .call-widget-content-action {
        border-radius: var(--border-radius-semirounded);
        display: flex;
        align-items: center;
        padding: var(--space-one);
        margin: var(--space-zero) var(--space-small);
      }
      .call-widget-content-action:hover {
        opacity: 0.8;
      }
      .mute {
        background-color: #ffffff33;
      }
      .decline {
        background-color: #fb6a8d;
      }
      .answer {
        background-color: $call-widget-color-green;
      }
    }
    .call-widget-content-conversation {
      padding: var(--space-slab);
      border-radius: var(--border-radius-medium);
      width: 66%;
      background-color: #ffffff1a;
      color: var(--white);
      font-size: var(--font-size-default);
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
    }
    .call-widget-content-conversation:hover {
      opacity: 0.8;
    }
    .call-widget-content-powered {
      color: var(--white);
      display: flex;
      align-items: center;
    }
  }
}

.call-widget-box:hover {
  .call-widget-background-image {
    transform: scale(2);
    filter: blur(3px);
  }
}

.call-widget-background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-one);
  background-image: linear-gradient(
    rgba($call-widget-color-default, 0.8),
    rgba($call-widget-color-default, 0.8)
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
}

.call-widget-caller-name {
  font-size: var(--font-size-mega);
  color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
