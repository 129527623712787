<template>
  <div class="message-text__wrap" :class="attachmentTypeClasses">
    <img
      v-if="isImage && !isImageError"
      :src="attachment.data_url"
      @click="onClick"
      @error="onImgError"
    />
    <video
      v-if="isVideo"
      :src="attachment.data_url"
      muted
      playsInline
      @error="onImgError"
      @click="onClick"
    />
    <WaveAudio
      v-else-if="isAudio"
      :audio-record="attachment"
      :muted="bannerVisibility"
      playback-rate
      @play="playHandler(attachment)"
      @next="nextHandler"
      @previous="previousHandler"
      @seek="seekHandler"
      @rate="rateHandler"
    />
    <gallery-view
      v-if="show"
      :show.sync="show"
      :attachment="attachment"
      :all-attachments="filteredCurrentChatAttachments"
      @error="onImgError"
      @close="onClose"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GalleryView from '../components/GalleryView.vue';
import WaveAudio from 'dashboard/components/app/WaveAudio';
import stickyBannerMixin from 'dashboard/mixins/stickyBannerMixin';

const ALLOWED_FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
};

export default {
  components: {
    GalleryView,
    WaveAudio,
  },
  mixins: [stickyBannerMixin],
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isImageError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChatAttachments: 'getSelectedChatAttachments',
    }),
    isImage() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.IMAGE;
    },
    isVideo() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.VIDEO;
    },
    isAudio() {
      return this.attachment.file_type === ALLOWED_FILE_TYPES.AUDIO;
    },
    attachmentTypeClasses() {
      return {
        image: this.isImage,
        video: this.isVideo,
      };
    },
    filteredCurrentChatAttachments() {
      const attachments = this.currentChatAttachments.filter(attachment =>
        ['image', 'video', 'audio'].includes(attachment.file_type)
      );
      return attachments;
    },
  },
  watch: {
    attachment() {
      this.isImageError = false;
    },
  },
  methods: {
    playHandler(attachment) {
      if (this.activeRecord && attachment.id === this.activeRecord.id) {
        return this.setPlayPause('play');
      }
      this.setAudioRecords([]);
      this.setBannerVisibility(false);
      let number = '';
      const conversationId =
        this.$route.params.conversation_id || this.$route.params.conversationId;

      const conversation = this.$store.getters.getConversationById(
        conversationId
      );
      const inboxData = this.$store.getters['inboxes/getInbox'](
        conversation.inbox_id
      );
      if (inboxData && inboxData.numbers?.length) {
        number = inboxData.numbers[0].phone_number;
      }
      const audios = this.getAudioAttachments(
        conversation,
        attachment.id,
        number
      );
      this.setAudioRecords(audios);
      this.setActiveRecord(audios.find(item => item.initial === true));
      return this.$nextTick(() => {
        this.setBannerVisibility(true);
        this.setPlayPause('playing');
      });
    },
    getAudioAttachments(conversation, attachment_id, number) {
      const audioAttachments = [];
      conversation.messages.forEach(message => {
        if (message.attachments) {
          const audioFiles = message.attachments.filter(
            attachment => attachment.file_type === 'audio'
          );
          audioFiles.forEach(audioFile => {
            audioFile.sender_id = message.sender?.id || null;
            audioFile.sender_name = message.sender?.name || null;
            audioFile.sender_avatar_url = message.sender?.avatar_url || null;
            audioFile.sender_flag =
              message.sender?.custom_attributes?.country || null;
            audioFile.sender_number = number;
            audioFile.initial = attachment_id === audioFile.id;
            audioFile.conversation_id = conversation.id;
            audioFile.inbox_id = conversation.inbox_id;
          });
          audioAttachments.push(...audioFiles);
        }
      });
      return audioAttachments;
    },
    onClose() {
      this.show = false;
    },
    onClick() {
      window.open(this.attachment.data_url, '_blank');
    },
    onImgError() {
      this.isImageError = true;
      this.$emit('error');
    },
  },
};
</script>
