import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      billingInfo: 'billing/getBillingInfo',
      countryBasePricing: 'billing/getCountryBasePricing',
      isFetchingUIFlag: 'billing/getIsFetchingUIFlag',
      numberPricing: 'billing/getNumberPricing',
    }),
    callBaseChannelPrice() {
      const interval = this.billingInfo.interval;
      const priceItems = this.countryBasePricing.callBaseChannelPrices.prices;
      const priceItem = priceItems.find(
        price => price.recurring.interval === interval
      );
      return priceItem ? priceItem.unit_amount : null;
    },
    isBillingDataLoaded() {
      return this.billingInfo && this.countryBasePricing;
    },
    isMonthly() {
      return this.billingInfo.interval === 'month';
    },
    isMonthlyText() {
      return this.isMonthly
        ? this.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.MONTH')
        : this.$t('INBOX_MGMT.ADD.CALL_CHANNEL.MAIN.NOTE.YEAR');
    },
    hasDefaultPaymentMethod() {
      return Boolean(this.billingInfo?.paymentMethods.length);
    },
  },
  methods: {
    formattedPrice(price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(price / 100);
    },
    fetchCountryBasePricing(data) {
      this.$store.dispatch('billing/getCountryBasePricing', data);
    },
    fetchNumberPricing(data) {
      this.$store.dispatch('billing/getNumberPricing', data);
    },
    buyChannel(data) {
      this.$store.dispatch('billing/buyChannel', data);
    },
    async deletePhoneNumber(data) {
      await this.$store.dispatch('billing/deletePhoneNumber', data);
    },
    async deleteCallBaseChannel(data) {
      await this.$store.dispatch('billing/deleteCallBaseChannel', data);
    },
    async provisionNumber(data) {
      await this.$store.dispatch('billing/provisionNumber', data);
    },
    activateSubscription(data) {
      this.$store.dispatch('billing/activateSubscription', data).then(resp => {
        if (resp.redirect) {
          window.location = resp.url;
        }
      });
    },
  },
};
