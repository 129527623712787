<template>
  <div class="columns profile--settings">
    <div class="small-12 columns">
      <div v-if="subscription !== null">
        <div class="border-bottom">
          <div class="page-title">
            <h5>
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.TITLE') }}
            </h5>
            <div class="badge">
              {{ planName }} {{ subscription.isTrial ? 'Trial' : '' }}
            </div>
          </div>
          <p class="text-muted mb-0">
            {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NOTE') }}
          </p>
        </div>
        <div class="plan-desc border-bottom">
          <h6>
            {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NAME') }}
            <span class="name">{{ planName }} Plan</span>
          </h6>

          <div
            v-dompurify-html="
              formatMessage(
                $t('BILLING_SETTINGS.CURRENT_PLAN.NEXT_CHARGE', {
                  date: nextPaymentDate,
                })
              )
            "
            class="text-muted"
          />
          <div v-if="subscription.isTrial" class="trial-box">
            <div class="emoji">👋</div>
            <div class="">
              <h6>
                {{
                  $t('BILLING_SETTINGS.CURRENT_PLAN.FREE_TRIAL_NOTE', {
                    planName,
                  })
                }}
              </h6>
              <p class="note text-muted">
                {{
                  $t('BILLING_SETTINGS.CURRENT_PLAN.TRIAL_END_NOTE', {
                    date: nextPaymentDate,
                    days: nextPaymentDays,
                  })
                }}
              </p>
            </div>
          </div>

          <h6 v-if="hasPaymentMethod" class="sub-expiry">
            {{
              $t('BILLING_SETTINGS.CURRENT_PLAN.PAYMENT_METHOD', {
                card: getCardLastNumbers,
                brand: getCardBrand,
                expire: getCardExpireDate,
              })
            }}
          </h6>
          <div class="sub-details">
            <h6>
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.SUBSCRIPTION_TITLE') }}
            </h6>
            <div class="table">
              <div class="row">
                <div class="col">
                  {{ subscription.quantity.toString() }} Agent{{
                    subscription.quantity > 1 ? '' : 's'
                  }}
                  <span class="text-muted">x</span> {{ planName }} Plan (at
                  {{ paymentDetail }}$/{{ paymentPeriod }})
                </div>
                <div class="col right">$ {{ paymentDetail.toFixed(2) }}</div>
              </div>
              <div class="row">
                <div class="col">Total</div>
                <div class="col right">$ {{ paymentDetail.toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <woot-loading-state
        v-else
        :message="$t('BILLING_SETTINGS.CURRENT_PLAN.LOADING')"
      />
      <div v-if="currentUser.role === 'administrator'">
        <billing-item
          :title="$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.TITLE')"
          :description="$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.DESCRIPTION')"
          :button-label="$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.BUTTON_TXT')"
          @click="onClickBillingPortal"
        />
      </div>
      <billing-item
        :title="$t('BILLING_SETTINGS.CHAT_WITH_US.TITLE')"
        :description="$t('BILLING_SETTINGS.CHAT_WITH_US.DESCRIPTION')"
        :button-label="$t('BILLING_SETTINGS.CHAT_WITH_US.BUTTON_TXT')"
        button-icon="chat-multiple"
        @click="onToggleChatWindow"
      />
    </div>
  </div>
</template>

<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from '../../../../mixins/account';
import BillingItem from './components/BillingItem.vue';
import wootConstants from 'dashboard/constants/globals.js';
import { format } from 'date-fns';
import axios from 'axios';
import auth from '../../../../api/auth';

const { SERVICES_PATH } = wootConstants;

export default {
  components: { BillingItem },
  mixins: [accountMixin, alertMixin, messageFormatterMixin],
  data() {
    return {
      SERVICES_PATH,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      subscription: 'billing/getBillingInfo',
    }),
    planName() {
      return `${this.subscription?.product?.name}`;
    },
    paymentPeriod() {
      return `${this.subscription.interval || 'month'}ly`;
    },
    paymentDetail() {
      return this.stripePriceToNum(this.subscription.totalPrice);
    },
    nextPaymentDate() {
      return this.subscription.isTrial
        ? this.fd(this.subscription.trialEndDate || new Date())
        : this.fd(this.subscription.endDate || new Date());
    },
    nextPaymentDays() {
      if (!this.subscription.trialEndDate) return 0;
      const date = new Date(this.subscription.trialEndDate);
      const diffTime = Math.abs(date - new Date());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    hasPaymentMethod() {
      return this.subscription.paymentMethods?.length > 0;
    },
    getCardLastNumbers() {
      return (
        '****' +
        (this.subscription.paymentMethods.length === 0
          ? ''
          : this.subscription.paymentMethods[0].card.last4)
      );
    },
    getCardBrand() {
      return this.subscription.paymentMethods.length === 0
        ? ''
        : this.subscription.paymentMethods[0].card.brand.toUpperCase();
    },
    getCardExpireDate() {
      const methods = this.subscription.paymentMethods;
      if (methods.length > 0) {
        return `${methods[0].card.exp_year}/${
          methods[0].card.exp_month < 10
            ? '0' + methods[0].card.exp_month
            : methods[0].card.exp_month
        }`;
      }
      return '';
    },
  },
  async mounted() {
    this.$store.dispatch('billing/getBillingInfo', this.accountId);
  },
  methods: {
    getBadgeType() {
      switch (this.subscription.status) {
        case 'active':
          return 'success';
        case 'trialing':
          return 'warning';
        case 'canceled':
          return 'danger';
        default:
          return '';
      }
    },
    stripePriceToNum(price) {
      if (!price) return '-';
      try {
        const ps = price.toString();
        const r0 = ps.substring(0, ps.length - 2);
        const r1 = ps.slice(-2);
        return parseFloat(r0 + '.' + r1);
      } catch (ex) {
        return 0;
      }
    },
    getSubscriptionPeriod() {
      return (
        this.fd(this.subscription.startDate) +
        ' - ' +
        this.fd(this.subscription.endDate)
      );
    },
    getTrialPeriod() {
      return (
        this.fd(this.subscription.trialStartDate) +
        ' - ' +
        this.fd(this.subscription.trialEndDate)
      );
    },

    fd(date) {
      return format(new Date(date), 'MMM d, yyyy');
    },
    onClickBillingPortal() {
      const authToken = auth.getAuthToken();
      axios
        .post(
          `/${SERVICES_PATH.BILLING}/v1/portal-session`,
          {
            accountId: this.accountId,
          },
          { headers: { authorization: authToken } }
        )
        .then(resp => {
          if (resp.data.redirect) {
            window.location = resp.data.url;
          }
        });
    },
    onToggleChatWindow() {
      if (window.$hoory) {
        window.$hoory.toggle();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  h5 {
    font-weight: var(--font-weight-bold);
  }

  .badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: var(--space-small);
    padding-left: var(--space-small);
    margin: var(--space-smaller);
    color: #603be7;
    border-radius: 40px;
    background: rgba(59, 76, 231, 0.1);
    font-size: 14px;
  }
}

.plan-desc {
  h6 {
    font-weight: var(--font-weight-bold);
  }

  .name {
    color: #603be7;
    margin-left: 4px;
  }

  .trial-box {
    background: rgba(183, 183, 183, 0.1);
    padding: var(--space-two);
    margin-top: var(--space-two);
    margin-bottom: var(--space-two);
    border-radius: 16px;
    display: inline-flex;
    flex-direction: row;
    max-width: 600px;

    .emoji {
      font-size: 30px;
      padding-right: 2%;
    }

    .note {
      margin-bottom: 0;
    }
  }

  .sub-expiry {
    color: #603be7;
    font-weight: var(--font-weight-normal);
    margin-bottom: var(--space-two);
  }
}

.sub-details {
  max-width: 600px;

  .table {
    display: flex;
    flex-direction: column;
    font-size: 15px;

    .row {
      padding-top: var(--space-small);
      padding-bottom: var(--space-small);
      flex: 1 1 auto;
      font-weight: var(--font-weight-medium);
    }

    .col {
      flex: 1 1;
    }

    .col.right {
      text-align: right;
    }
  }
}

.text-muted {
  color: #787887;
}

.mb-0 {
  margin-bottom: 0;
}

.manage-subscription {
  align-items: center;
  background: var(--white);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-small);
  padding: var(--space-medium) var(--space-normal);
}

.border-bottom {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: var(--space-normal);
  padding-bottom: var(--space-normal);
}

.manage-subscription {
  .manage-subscription--description {
    margin-bottom: 0;
  }
}
</style>
