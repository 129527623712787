<template>
  <div
    class="container"
    :style="{ top: y + 'px', left: x + 'px' }"
    @mousedown="startDrag"
  >
    <section id="info" class="left-column">
      <div id="client-name" />
      <div id="output-selection" class="hide">
        <label>Ringtone Devices</label>
        <select id="ringtone-devices" multiple />
        <label>Speaker Devices</label>
        <select id="speaker-devices" multiple />
      </div>
    </section>
    <section class="center-column">
      <button id="startup-button">Receive Call</button>
      <div id="call-controls" class="hide">
        <form>
          <label for="agent-phone-number">From Phone Number:</label>
          <input id="agent-phone-number" type="text" value="+14193181614" />
          <label for="phone-number">To Phone Number</label>
          <input id="phone-number" type="text" placeholder="+15552221234" />
          <button id="button-call" type="submit">Call</button>
        </form>
        <button id="button-hangup-outgoing" class="hide">
          Hang Up
        </button>
        <div id="incoming-call" class="hide">
          <p class="instructions">
            Incoming Call from <span id="incoming-number" />
          </p>
          <button id="button-accept-incoming">Accept</button>
          <button id="button-reject-incoming">Reject</button>
          <button id="button-hangup-incoming" class="hide">
            Hangup
          </button>
        </div>
        <div id="volume-indicators" class="hide">
          <label>Mic Volume</label>
          <div id="input-volume" />
          <label>Speaker Volume</label>
          <div id="output-volume" />
        </div>
      </div>
    </section>
    <section class="right-column">
      <div id="log" class="scrollable-view hide" />
    </section>
  </div>
</template>

<script>
import { Device } from '@twilio/voice-sdk';
import callApi from '../../api/call';

export default {
  data() {
    return {
      x: 100, // Initial x position
      y: 100, // Initial y position
      isDragging: false,
      offsetX: 0,
      offsetY: 0,
    };
  },
  mounted() {
    const speakerDevices = document.getElementById('speaker-devices');
    const ringtoneDevices = document.getElementById('ringtone-devices');
    const outputVolumeBar = document.getElementById('output-volume');
    const inputVolumeBar = document.getElementById('input-volume');
    const volumeIndicators = document.getElementById('volume-indicators');
    const callButton = document.getElementById('button-call');
    const outgoingCallHangupButton = document.getElementById(
      'button-hangup-outgoing'
    );
    const callControlsDiv = document.getElementById('call-controls');
    const audioSelectionDiv = document.getElementById('output-selection');
    const logDiv = document.getElementById('log');
    const incomingCallDiv = document.getElementById('incoming-call');
    const incomingCallHangupButton = document.getElementById(
      'button-hangup-incoming'
    );
    const incomingCallAcceptButton = document.getElementById(
      'button-accept-incoming'
    );
    const incomingCallRejectButton = document.getElementById(
      'button-reject-incoming'
    );
    const phoneNumberInput = document.getElementById('phone-number');
    const agentPhoneNumberInput = document.getElementById('agent-phone-number');
    const incomingPhoneNumberEl = document.getElementById('incoming-number');
    const startupButton = document.getElementById('startup-button');

    let device;
    let token;

    // Event Listeners

    callButton.onclick = e => {
      e.preventDefault();
      makeOutgoingCall();
    };
    speakerDevices.addEventListener('change', updateOutputDevice);
    ringtoneDevices.addEventListener('change', updateRingtoneDevice);

    // SETUP STEP 1:
    // Browser client should be started after a user gesture
    // to avoid errors in the browser console re: AudioContext
    startupButton.addEventListener('click', startupClient);

    // SETUP STEP 2: Request an Access Token
    async function startupClient() {
      log('Requesting Access Token...');

      try {
        const response = await callApi.requestToken();
        const data = response.data;
        token = data.token;
        setClientNameUI(data.identity);
        intitializeDevice();
      } catch (err) {
        log(
          'An error occurred. See your browser console for more information.'
        );
      }
    }

    // SETUP STEP 3:
    // Instantiate a new Twilio.Device
    function intitializeDevice() {
      logDiv.classList.remove('hide');
      log('Initializing device');
      device = new Device(token, {
        logLevel: 1,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ['opus', 'pcmu'],
      });

      addDeviceListeners(device);

      // Device must be registered in order to receive incoming calls
      device.register();
    }

    // SETUP STEP 4:
    // Listen for Twilio.Device states
    function addDeviceListeners(passedDevice) {
      passedDevice.on('registered', () => {
        log('Twilio.Device Ready to make and receive calls!');
        callControlsDiv.classList.remove('hide');
      });

      passedDevice.on('error', error => {
        log('Twilio.Device Error: ' + error.message);
      });

      passedDevice.on('incoming', handleIncomingCall);

      passedDevice.audio.on(
        'deviceChange',
        updateAllAudioDevices.bind(passedDevice)
      );

      // Show audio selection UI if it is supported by the browser.
      if (passedDevice.audio.isOutputSelectionSupported) {
        audioSelectionDiv.classList.remove('hide');
      }
    }

    // MAKE AN OUTGOING CALL

    async function makeOutgoingCall() {
      let params = {
        // get the phone number to call from the DOM
        To: phoneNumberInput.value,
        Agent: agentPhoneNumberInput.value,
      };

      if (device) {
        log(`Attempting to call ${params.To} ...`);

        // Twilio.Device.connect() returns a Call object
        const call = await device.connect({ params });

        // add listeners to the Call
        // "accepted" means the call has finished connecting and the state is now "open"
        call.on('accept', updateUIAcceptedOutgoingCall);
        call.on('disconnect', updateUIDisconnectedOutgoingCall);
        call.on('cancel', updateUIDisconnectedOutgoingCall);
        call.on('error', error => {
          log('An error has occurred: ' + error);
        });

        outgoingCallHangupButton.onclick = () => {
          log('Hanging up ...');
          call.disconnect();
        };
      } else {
        log('Unable to make call.');
      }
    }

    function updateUIAcceptedOutgoingCall(call) {
      log('Call in progress ...');
      callButton.disabled = true;
      outgoingCallHangupButton.classList.remove('hide');
      volumeIndicators.classList.remove('hide');
      bindVolumeIndicators(call);
    }

    function updateUIDisconnectedOutgoingCall() {
      log('Call disconnected.');
      callButton.disabled = false;
      outgoingCallHangupButton.classList.add('hide');
      volumeIndicators.classList.add('hide');
    }

    // HANDLE INCOMING CALL

    function handleIncomingCall(call) {
      log(`Incoming call from ${call.parameters.From}`);

      // show incoming call div and incoming phone number
      incomingCallDiv.classList.remove('hide');
      incomingPhoneNumberEl.innerHTML = call.parameters.From;

      // add event listeners for Accept, Reject, and Hangup buttons
      incomingCallAcceptButton.onclick = () => {
        acceptIncomingCall(call);
      };

      incomingCallRejectButton.onclick = () => {
        rejectIncomingCall(call);
      };

      incomingCallHangupButton.onclick = () => {
        hangupIncomingCall(call);
      };

      // add event listener to call object
      call.on('cancel', handleDisconnectedIncomingCall);
      call.on('disconnect', handleDisconnectedIncomingCall);
      call.on('reject', handleDisconnectedIncomingCall);
    }

    // ACCEPT INCOMING CALL

    function acceptIncomingCall(call) {
      call.accept();

      // update UI
      log('Accepted incoming call.');
      incomingCallAcceptButton.classList.add('hide');
      incomingCallRejectButton.classList.add('hide');
      incomingCallHangupButton.classList.remove('hide');
    }

    // REJECT INCOMING CALL

    function rejectIncomingCall(call) {
      call.reject();
      log('Rejected incoming call');
      resetIncomingCallUI();
    }

    // HANG UP INCOMING CALL

    function hangupIncomingCall(call) {
      call.disconnect();
      log('Hanging up incoming call');
      resetIncomingCallUI();
    }

    // HANDLE CANCELLED INCOMING CALL

    function handleDisconnectedIncomingCall() {
      log('Incoming call ended.');
      resetIncomingCallUI();
    }

    // MISC USER INTERFACE

    // Activity log
    function log(message) {
      logDiv.innerHTML += `<p class="log-entry">&gt;&nbsp; ${message} </p>`;
      logDiv.scrollTop = logDiv.scrollHeight;
    }

    function setClientNameUI(clientName) {
      let div = document.getElementById('client-name');
      div.innerHTML = `Your client name: <strong>${clientName}</strong>`;
    }

    function resetIncomingCallUI() {
      incomingPhoneNumberEl.innerHTML = '';
      incomingCallAcceptButton.classList.remove('hide');
      incomingCallRejectButton.classList.remove('hide');
      incomingCallHangupButton.classList.add('hide');
      incomingCallDiv.classList.add('hide');
    }

    // AUDIO CONTROLS

    // async function getAudioDevices() {
    //   await navigator.mediaDevices.getUserMedia({ audio: true });
    //   updateAllAudioDevices.bind(device);
    // }

    function updateAllAudioDevices() {
      if (device) {
        updateDevices(speakerDevices, device.audio.speakerDevices.get());
        updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());
      }
    }

    function updateOutputDevice() {
      const selectedDevices = Array.from(speakerDevices.children)
        .filter(node => node.selected)
        .map(node => node.getAttribute('data-id'));

      device.audio.speakerDevices.set(selectedDevices);
    }

    function updateRingtoneDevice() {
      const selectedDevices = Array.from(ringtoneDevices.children)
        .filter(node => node.selected)
        .map(node => node.getAttribute('data-id'));

      device.audio.ringtoneDevices.set(selectedDevices);
    }

    function bindVolumeIndicators(call) {
      call.on('volume', (inputVolume, outputVolume) => {
        let inputColor = 'red';
        if (inputVolume < 0.5) {
          inputColor = 'green';
        } else if (inputVolume < 0.75) {
          inputColor = 'yellow';
        }

        inputVolumeBar.style.width = Math.floor(inputVolume * 300) + 'px';
        inputVolumeBar.style.background = inputColor;

        let outputColor = 'red';
        if (outputVolume < 0.5) {
          outputColor = 'green';
        } else if (outputVolume < 0.75) {
          outputColor = 'yellow';
        }

        outputVolumeBar.style.width = Math.floor(outputVolume * 300) + 'px';
        outputVolumeBar.style.background = outputColor;
      });
    }

    // Update the available ringtone and speaker devices
    function updateDevices(selectEl, selectedDevices) {
      selectEl.innerHTML = '';

      device.audio.availableOutputDevices.forEach((availableDevice, id) => {
        let isActive = selectedDevices.size === 0 && id === 'default';
        selectedDevices.forEach(deviceItem => {
          if (deviceItem.deviceId === id) {
            isActive = true;
          }
        });

        let option = document.createElement('option');
        option.label = availableDevice.label;
        option.setAttribute('data-id', id);
        if (isActive) {
          option.setAttribute('selected', 'selected');
        }
        selectEl.appendChild(option);
      });
    }
  },
  methods: {
    startDrag(event) {
      if (event.target.closest('.content')) {
        return;
      }
      this.isDragging = true;
      this.offsetX = event.clientX - this.x;
      this.offsetY = event.clientY - this.y;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        this.x = event.clientX - this.offsetX;
        this.y = event.clientY - this.offsetY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.stopDrag);
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 400px;
  height: 640px;
  background-color: aquamarine;
  cursor: grab;
  margin: 3px;
  padding: 10px;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container:active {
  cursor: grabbing;
}

.scrollable-view {
  overflow-y: auto;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  background-color: #f9f9f9;
}

button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
